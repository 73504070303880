import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { trackPromise } from "react-promise-tracker";
import { CheckoutLogs } from "./checkoutLogs.type";

const initialState: CheckoutLogs = {
   data: [],
};

const url = process.env.REACT_APP_API_URL;

const getCheckoutLogs = createAsyncThunk(
  "checkoutLogs/getCheckoutLogs",
  async (arg: void, { rejectWithValue, getState, dispatch }: any) => {
    const pathname = `/checkout/get/logs`;
    const token = getState().login.token;
    const headers = {
      "x-auth-token": token,
    };
    const params = {
      dateFrom: new Date(),
      dateTo: new Date(),
    };
    
    try {
      /** make api call */
      const response = await trackPromise(
        axios.post(url!.concat(pathname), params, { headers })
      );
      console.log("Success response from checkout logs :", response.data);
      dispatch(checkoutLogsActions.setData(response.data));
      return response.data;
    } catch (e) {
      console.error(e);
      return rejectWithValue(e.response?.data);
    }
  }
);

const searchCheckoutLogs = createAsyncThunk(
  "checkoutLogs/searchCheckoutLogs",
  async (arg: any, { rejectWithValue, getState, dispatch }: any) => {
    const pathname = `/checkout/get/search`;
    const token = getState().login.token;
    const headers = {
      "x-auth-token": token,
    };
    const params = {
      dateFrom: arg.from,
      dateTo: arg.to,
      query: arg.query,
    };
    try {
      /** make api call */
      const response = await trackPromise(
        axios.post(url!.concat(pathname), params, { headers })
      );
      console.log("Success response from loan requests :", response.data);
      dispatch(checkoutLogsActions.setData(response.data));
      return response.data;
    } catch (e) {
      console.error(e);
      return rejectWithValue(e.response?.data);
    }
  }
);

const checkoutLogsSlice = createSlice({
 
  name: "checkoutLogs",

  initialState: initialState,

  reducers: {
    setCheckoutLogs: (state, action) => {
      return { ...state, ...action.payload };
    },
    setData: (state, action) => {
      state.data = action.payload;
    },
    reset: () => initialState,
    // Add here reducers
    // ...
  },

});


export const checkoutLogsReducer = checkoutLogsSlice.reducer;

export const checkoutLogsActions = { ...checkoutLogsSlice.actions, getCheckoutLogs, searchCheckoutLogs };
