export default function getCountryAlias(cc) {
  const obj = {
    "1": "us",
    "20": "eg",
    "27": "za",
    "30": "gr",
    "31": "nl",
    "32": "be",
    "33": "fr",
    "34": "es",
    "36": "hu",
    "39": "it",
    "40": "ro",
    "41": "ch",
    "43": "at",
    "44": "gb",
    "45": "dk",
    "46": "se",
    "47": "no",
    "48": "pl",
    "49": "de",
    "51": "pe",
    "52": "mx",
    "53": "cu",
    "54": "ar",
    "55": "br",
    "56": "cl",
    "57": "co",
    "58": "ve",
    "60": "my",
    "61": "au",
    "62": "id",
    "63": "ph",
    "64": "nz",
    "65": "sg",
    "66": "th",
    "81": "jp",
    "82": "kr",
    "84": "vn",
    "86": "cn",
    "90": "tr",
    "91": "in",
    "92": "pk",
    "94": "lk",
    "95": "mm",
    "98": "ir",
    "211": "ss",
    "212": "ma",
    "213": "dz",
    "216": "tn",
    "218": "ly",
    "220": "gm",
    "221": "sn",
    "222": "mr",
    "223": "ml",
    "224": "gn",
    "225": "ci",
    "226": "bf",
    "227": "ne",
    "228": "tg",
    "229": "bj",
    "230": "mu",
    "231": "lr",
    "232": "sl",
    "233": "gh",
    "234": "ng",
    "235": "td",
    "236": "cf",
    "237": "cm",
    "238": "cv",
    "239": "st",
    "240": "gq",
    "241": "ga",
    "242": "cg",
    "243": "cd",
    "244": "ao",
    "245": "sz",
    "962": "jo",
    "963": "sy",
    "964": "iq",
    "965": "kw",
    "966": "sa",
    "967": "ye",
    "968": "om",
    "970": "ps",
    "971": "ae",
    "973": "bh",
    "974": "qa",
  };
  return obj[cc];
}
