import React, { useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";
import Box from '@mui/material/Box';
import { RootState } from "&store/store";
import Loader from "&styled/loader/loader";
import {servicesActions} from '&features/services/services.slice';

type ReduxProps = ConnectedProps<typeof connector>;

const PaytabWelcomeComponent = (props: ReduxProps) => {
  const {serviceId,orderId, getService} = props;

  useEffect(() => {
(async()=>{
  // get service by sid
  const {payload} = await getService(serviceId)
  
  // get the redirect url
  const clientUrl = payload.portalUrl;
  // modify the response and redirect to client
   let redirectStr = `?status=PENDING&orderId=${orderId}`

 window.location.href = clientUrl + redirectStr;
})()
  }, [serviceId, getService, orderId]);

  return (
    <Box sx={{width:"100vw", height: "100vh", display:"flex", justifyContent:"center", alignItems:"center"}}>
      <Loader/>;
    </Box>
  )
};

/**
 * Maps state variables from redux store to props of currect component
 * @param state
 */
const mapStateToProps = (state: RootState, ownProps) => ({
  serviceId: ownProps.match.params.serviceId,
  orderId: ownProps.match.params.orderId,

});

/**
 * Maps actions from slices to props
 */
const mapDispatchToProps = {
  getService: servicesActions.getServiceBySid,

};

/**
 * Connects component to redux store
 */
const connector = connect(mapStateToProps, mapDispatchToProps);
const PaytabWelcomeComponentRedux = connector(PaytabWelcomeComponent);

export { PaytabWelcomeComponentRedux as PaytabWelcomeComponent };
