import React, {  useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import {
  endOfDay,
  endOfToday,
  endOfYesterday,
  startOfDay,
  startOfToday,
  startOfYesterday,
} from "date-fns";
import { isEmpty } from "lodash";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { RootState } from "&store/store";
import CalendarIcon from "&assets/icons/calendar";
import TotalRevenueIcon from "&assets/icons/total-revenue";
import TotalNumberOfTransactionsIcon from "&assets/icons/number-of-trans";
import TotalServiceChargesIcon from "&assets/icons/service-charges";
import { collectionActions } from "./collection.slice";
import {
  dateByFormat,
  getMonthsBefore,
  getWeeksBefore,
  getYearsBefore,
} from "&utils/dates";
import SelectComponent from "&styled/form/select";
import { getCurrentUser } from "&config/getCurrentUser";
import { SubmitButton } from "&styled/button/button.component";
import { DashboardSummaryCard } from "&styled/dashboardSummaryCard/dashboardSummaryCard.component";
import { LineChartComponent } from "&styled/lineChart/lineChart.component";
import { PieChartComponent } from "&styled/pieChart/pieChart.component";
import { BarChartComponent } from "&styled/barChart/barChart.component";
import CustomDateRangeModal from "&styled/customDateModal";

type SelectFormtted = {
  label: string;
  value: string;
};
type ReduxProps = ConnectedProps<typeof connector>;

const CollectionComponent = (props: ReduxProps) => {
  const { getFilterData, searchCollectionAnalytics, token } = props;
  const user: any = getCurrentUser(token);
  const [selectedDate, setSelectedDate] = useState("");
  const [dateRange, setDateRange] = useState<any>({});
  const [analytics, setAnalytics] = useState<any>({});
  const [paymentMethod, setPaymentMethod] = useState("");
  const [selectedMerchant, setSelectedMerchant] = useState("");
  const [openCustomRangeModal, setOpenCustomRangeModal] = useState(false);
  const [operators, setOperators] = useState<SelectFormtted[]>([]);
  const [merchants, setMerchants] = useState<SelectFormtted[]>([]);

  useEffect(() => {
    (async () => {
      const { payload } = await getFilterData();
      const { operators, merchants } = payload;

      const formattedOperators: SelectFormtted[] = [
        { label: "All payment methods", value: "all" },
      ];
      const formattedMerchants: SelectFormtted[] = [
        { label: "All vendors", value: "all" },
      ];
      merchants?.forEach((mer) => {
        formattedMerchants.push({ label: mer.name, value: mer.name });
      });
      operators.forEach((operator) => {
        formattedOperators.push({ label: operator.name, value: operator._id });
      });

      setMerchants(formattedMerchants);
      setOperators(formattedOperators);
    })();
    if (user.userTypeId !== "Walee") {
      setSelectedMerchant(user.userTypeId);
    } else {
      setSelectedMerchant("all");
    }

    setPaymentMethod("all");
    setSelectedDate("last-week");
    setDateRange({
      dateFrom: getWeeksBefore(1),
      dateTo: endOfToday(),
    });
  }, [
    getFilterData,
    paymentMethod,
    searchCollectionAnalytics,
    selectedMerchant,
    user.userTypeId,
  ]);

  useEffect(() => {
    (async () => {
      const searchData = {
        dateFrom: getWeeksBefore(1),
        dateTo: endOfToday(),
        paymentMethod: "all",
      };
      if (user.userTypeId !== "Walee") {
        searchData["merchant"] = user.userTypeId;
      } else {
        searchData["merchant"] = "all";
      }
      const { payload: analytics } = await searchCollectionAnalytics(
        searchData
      );
      setAnalytics(analytics?.data);
    })();
  }, [
    paymentMethod,
    searchCollectionAnalytics,
    selectedMerchant,
    user.userTypeId,
  ]);
  const handleDateSelection = (e) => {
    const value = e?.target?.value;
    if (value === "today") {
      setDateRange({
        dateFrom: startOfToday(),
        dateTo: endOfToday(),
      });
    } else if (value === "yesterday") {
      setDateRange({
        dateFrom: startOfYesterday(),
        dateTo: endOfYesterday(),
      });
    } else if (value === "last-week") {
      setDateRange({
        dateFrom: getWeeksBefore(1),
        dateTo: endOfToday(),
      });
    } else if (value === "last-month") {
      setDateRange({
        dateFrom: getMonthsBefore(1),
        dateTo: endOfToday(),
      });
    } else if (value === "last-year") {
      setDateRange({
        dateFrom: getYearsBefore(1),
        dateTo: endOfToday(),
      });
    } else if (value === "custom-dates") {
      setOpenCustomRangeModal(true);
    }
    setSelectedDate(value);
  };

  const handleCloseModal = () => {
    // If user closed modal reset value to none
    setOpenCustomRangeModal(false);
    setSelectedDate("last-week");
  };

  const handleCustomRangeModalConfirmation = (dateFrom: Date, dateTo: Date) => {
    setDateRange({
      dateFrom: startOfDay(dateFrom),
      dateTo: endOfDay(dateTo),
    });
    setOpenCustomRangeModal(false);
  };
  
  const getCustomDatesLabel = () => {
    return selectedDate === "custom-dates" && !isEmpty(dateRange)
      ? dateByFormat(dateRange?.dateFrom, "DD-MM-YYYY") +
          " - " +
          dateByFormat(dateRange?.dateTo, "DD-MM-YYYY")
      : "Custom Dates";
  };

  const handlePaymentMethod = (e) => {
    setPaymentMethod(e.target.value);
  };

  const handleMerchant = (e) => {
    setSelectedMerchant(e.target.value);
  };

  const handleSearch = async () => {
    const searchData = {
      merchant: selectedMerchant,
      dateFrom: dateRange.dateFrom,
      dateTo: dateRange.dateTo,
      paymentMethod: paymentMethod,
    };
    const { payload } = await searchCollectionAnalytics(searchData);
    setAnalytics(payload.data);
  };

  const formateSummary = (summary) => {
    if (Array.isArray(summary)) {
      const revSummary = summary.reduce((acc, cur) => {
        acc["waleeShare"] = (acc["waleeShare"] || 0) + cur["waleeShare"];
        acc["totalAmount"] = (acc["totalAmount"] || 0) + cur["totalAmount"];
        acc["totalNumberOfTransactions"] =
          (acc["totalNumberOfTransactions"] || 0) +
          cur["totalNumberOfTransactions"];

        return acc;
      }, {});
      return revSummary;
    }
  };

  const formateTransactionRate = (transactionRates) => {
    const formattedTransactionRates: any[] = [];
    if (Array.isArray(transactionRates)) {
      formattedTransactionRates.push({
        name: "Successful",
        value: transactionRates[0].successful,
      });
      formattedTransactionRates.push({
        name: "Failed",
        value: transactionRates[0].failed,
      });
    }
    return formattedTransactionRates;
  };

  const formateTransactionByPaymentMethod = (transactionsByPaymentMethod) => {
    const formatted: any[] = [];
    if (Array.isArray(transactionsByPaymentMethod)) {
      transactionsByPaymentMethod.forEach((item) => {
        const currentItem = formatted.find(
          (existingItem) => existingItem.operator === item.operator
        );
        if (currentItem && Object.keys(currentItem).length) {
          currentItem.Successful += item.Successful;
          currentItem.Failed += item.Failed;
        } else {
          formatted.push(item);
        }
      });
    }
    return formatted;
  };
  return (
    <>
    <Box sx={{ overflow: "auto", height: "95%" }}>
      {/*Heading and Search filters*/}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            color: "#111111",
            font: "normal normal 600 20px/30px Poppins",
          }}
        >
          Collection Overview
        </Box>
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <SelectComponent
            onSelect={(e) => handleDateSelection(e)}
            value={selectedDate}
            size="small"
            Icon={CalendarIcon}
            onClick={(e) => {
              if (e?.target?.dataset?.value !== "custom-dates") return;
              setOpenCustomRangeModal(true);
            }}
            menuItems={[
              { label: "Today", value: "today" },
              { label: "Yesterday", value: "yesterday" },
              { label: "Last 7 days", value: "last-week" },
              { label: "Last 30 days", value: "last-month" },
              { label: getCustomDatesLabel(), value: "custom-dates" },
            ]}
          />

          <SelectComponent
            onSelect={(e) => handlePaymentMethod(e)}
            value={paymentMethod}
            size="small"
            menuItems={operators || []}
          />

          {user.userTypeId === "Walee" && (
            <SelectComponent
              onSelect={(e) => handleMerchant(e)}
              value={selectedMerchant}
              size="small"
              menuItems={merchants || []}
            />
          )}
          <Box>
            <SubmitButton title={"Search"} handlePress={handleSearch} />
          </Box>
        </Box>
      </Box>
      {/*Dashboard OverView*/}

      <Grid container spacing={1} marginTop={2}>
        <Grid item lg={4} md={1}>
          <DashboardSummaryCard
            title="Total Revenue"
            titleValue={formateSummary(analytics.summary)?.totalAmount}
            Icon={TotalRevenueIcon}
          />
        </Grid>
        <Grid item lg={4} md={1}>
          <DashboardSummaryCard
            title="Total Service Charges"
            titleValue={formateSummary(analytics.summary)?.waleeShare}
            Icon={TotalServiceChargesIcon}
          />
        </Grid>
        <Grid item lg={4} md={1}>
          <DashboardSummaryCard
            title="Total No. of Transaction"
            titleValue={
              formateSummary(analytics.summary)?.totalNumberOfTransactions
            }
            Icon={TotalNumberOfTransactionsIcon}
          />
        </Grid>

        {/*Revenue Over Time*/}
        <Grid item lg={8} md={1}>
          <LineChartComponent
            title="Revenue Overtime"
            subTitleDark="Total Revenue"
            subTitleLight="Service Charges"
            dataKeyDark="amount"
            dataKeyLight="serviceCharges"
            heading={"Amount (PKR)"}
            isCurrency={true}
            data={analytics?.revenueOverTime || []}
          />
        </Grid>
        {/*Transaction rate*/}
        <Grid item lg={4} md={1}>
          <PieChartComponent
            data={formateTransactionRate(analytics?.transactionRate)}
          />
        </Grid>
        {/*Transaction Over Time*/}
        <Grid item lg={6} md={1}>
          <LineChartComponent
            title="Transaction Over time"
            subTitleDark="Successful"
            subTitleLight="Failed"
            dataKeyDark="successful"
            dataKeyLight="failed"
            heading="No. Of Transactions"
            isCurrency={false}
            data={analytics?.transactionOverTime || []}
          />
        </Grid>
        {/*Transaction by payment method*/}
        <Grid item lg={6} md={1}>
          <BarChartComponent
            title="Transaction by Payment Method"
            heading="No. Of Transactions"
            data={
              formateTransactionByPaymentMethod(
                analytics?.transactionByPaymentMethod
              ) || []
            }
          />
        </Grid>
      </Grid>
    </Box>
    <CustomDateRangeModal
        open={openCustomRangeModal}
        handleClose={() => handleCloseModal()}
        onConfirm={(dateFrom, dateTo) => handleCustomRangeModalConfirmation(dateFrom, dateTo)}
      />
    </>
  );
};

/**
 * Maps state variables from redux store to props of currect component
 * @param state
 */
const mapStateToProps = (state: RootState) => ({
  token: state.login.token,
});

const mapDispatchToProps = {
  getFilterData: collectionActions.getFilterData,
  searchCollectionAnalytics: collectionActions.searchCollectionAnalytics,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
const CollectionComponentRedux = connector(CollectionComponent);

export { CollectionComponentRedux as CollectionComponent };
