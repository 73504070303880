import * as React from "react";
import { Box, MenuItem, Select, TextField } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import getCountryAlias from "../../config/getCountryAlias";

interface Props {
  phoneNbr: string;
  onTextChange: any;
  handleCountryCodeChange: any;
  countryCode: string;
  hasError: boolean;
  label?: string;
  errorMessage?: string;
  countryCodes: any[]
}

export default function TextWithSelect({
  phoneNbr,
  onTextChange,
  handleCountryCodeChange,
  countryCode,
  hasError,
  label,
  errorMessage,
  countryCodes,
}: Props) {
  const countries:any = [...countryCodes];
  return (
    <div dir="ltr">
      {label && <label
        htmlFor={"countryCode"}
        style={{
          color: hasError ? "red" : "#ffffff",
          fontSize: "14px",
          marginBottom: "1rem",
        }}
      >
        {label}
      </label>}
      <Box
        sx={{
          // height: "58px",
          backgroundColor: "#ffffff",
          border: hasError ? "1px solid red" : "1px solid #eee",
          borderRadius: "30px",
          display: "flex",
          marginTop: "1rem",
        }}
      >
        <Select
          labelId="selectPrefixLabel"
          id="selectPrefix"
          displayEmpty
          value={countryCode}
          onChange={handleCountryCodeChange}
          disableUnderline={true}
          variant="standard"
          IconComponent={() => (
            <KeyboardArrowDownIcon sx={{ width: "16px", height: "16px" }}/>
          )}
          sx={{
            // borderRight: "1px solid #484848A3",
            color: "#000000",
            fontWeight: "300",
            padding: "0 18px",
            width: "150px",
          }}
        >
          {countries.length > 0 &&
            countries.map(({ value, label }) => (
              <MenuItem value={value} key={value}>
                {value !== "rest" && <Box sx={{ display: "flex", gap: 0.5, alignItems: "center" }}>
                  <img src={`/flags/${getCountryAlias(value)}.svg`} alt="afg" width={20} height={16}/>
                  {label}
                </Box>}
              </MenuItem>
            ))}
        </Select>

        <TextField
          id="inputMsisdn"
          value={phoneNbr}
          fullWidth
          variant="standard"
          onChange={onTextChange}
          size="small"
          style={{
            height: "48px",
            textAlign: "center",
          }}
          sx={{
            margin: 0,
            padding: 0,
          }}
          placeholder={"Mobile Number"}
          InputLabelProps={{
            shrink: false,
            style: {
              // marginTop: "-0.5rem",
              height: "40px",
              padding: 0,
            },
          }}
          InputProps={{
            "style": {
              // marginTop: "5px",
              // marginTop: "5px",
              color: "#000",
              fontSize: "14px",
              fontWeight: 300,
              height: "48px",
              padding: 0,
            },
            "disableUnderline": true,
          }}
        />
      </Box>
      <div
        style={{
          color: "red",
          display: hasError ? "block" : "none",
          fontSize: 14,
          textAlign:"center",
          marginTop:"0.5rem"
        }}
      >
        {errorMessage}
      </div>
    </div>
  );
}
