import * as React from "react";
import TextField from "@mui/material/TextField";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";

type Props ={
  label: string;
  value: any;
  onChange: any;
  views? : any;
}
export default function DatePickerComponent(props: Props) {
  const {label, value, onChange, views} = props;
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        label={label}
        views={views}
        value={value}
        onChange={onChange}
        renderInput={(params) => <TextField {...params} fullWidth size="small"/>}
      />
    </LocalizationProvider>
  );
}
