import React, { useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";
import moment from "moment";
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import EditIcon from "&assets/icons/edit";
import DeleteIcon from "&assets/icons/delete";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import HistoryIcon from "&assets/icons/history";
import CardContent from "@mui/material/CardContent";
import { useHistory } from "react-router-dom";
import PageHeader from "&styled/page-header";
import FabButton from "&styled/FabButton";
import { RootState } from "&store/store";
import {
  warningAlert,
  successAlert,
  errorAlert,
} from "&config/swalGenerator";
import { merchantsActions } from "./merchants.slice";

type ReduxProps = ConnectedProps<typeof connector>;

const MerchantsComponent = (props: ReduxProps) => {
  const history = useHistory();
  const { state, getMerchants, deleteMerchant, toggleMerchantStatus } = props;
  const { data } = state;
  const [pageSize, setPageSize] = React.useState(25);
  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Account Name",
      flex: 1,
      headerAlign: "left",
      align: "left",
    },
    {
      field: "country",
      headerName: "Country",
      flex: 1,
      headerAlign: "left",
      align: "left",
    },
    {
      field: "currency",
      headerName: "Currency",
      width: 150,
      headerAlign: "left",
      align: "left",
    },
    {
      field: "accountBalance",
      headerName: "Account Balance",
      width: 150,
      headerAlign: "left",
      align: "left",
    },
    {
      field: "status",
      headerName: "Status",
      renderCell: ({ row }) => renderBtnToggle(row),
      flex: 1,
      headerAlign: "left",
      align: "left",
    },

    {
      field: "createdDate",
      headerName: "Created Date",
      flex: 1,
      renderCell: ({ row }) => moment(row.createdDate).format("DD MMM YYYY"),
      headerAlign: "left",
      align: "left",
    },
    {
      field: "actions",
      headerName: "",
      renderCell: ({ row }) => renderButtons(row),
      width: 200,
      headerAlign: "left",
      align: "left",
    },
  ];

  useEffect(() => {
    (async () => {
      await getMerchants();
    })();
  }, [getMerchants]);

  const onDelete = async (event, id: string) => {
    const value = await warningAlert();
    if (value) {
      try {
        await deleteMerchant(id);

        successAlert("Deleted", "Merchant has been deleted successfully!");
      } catch (ex) {
        errorAlert();
      }
    }
  };

  const toggleStatus = async (row) => {
    try {
      const params = {
        status: row.status === "Active" ? "Inactive" : "Active",
        name: row.name,
        _id: row._id,
      };
      await toggleMerchantStatus(params);
    } catch (ex) {
      alert("Something went wrong");
      console.log(ex);
    }
  };

  const renderBtnToggle = (row) => {
    return (
      <Box
        sx={{
          backgroundColor: row.status === "Active" ? "#ECECEC" : "#D6FFD9",
          color: row.status === "Active" ? "#727272" : "#0FA01A",
          width: "85px",
          textAlign: "center",
          borderRadius: "5px",
          padding: "5px",
          fontSize: "12px",
          lineHeight: "18px",
          cursor: "pointer",
        }}
        onClick={() => toggleStatus(row)}
      >
        {row.status === "Active" ? "Deactivate" : "Activate"}
      </Box>
    );
  };

  const renderBtnHistory = (row) => {
    return (
      <FabButton
        label="History"
        color="secondary"
        onClick={() => history.push(`/merchants/history/${row._id}`)}
      >
        <HistoryIcon />
      </FabButton>
    );
  };
  const renderButtons = (row) => {
    return (
      <Box sx={{ display: "flex", gap: 1 }}>
        {renderBtnAccountBalance(row)}
        {renderBtnEdit(row)}
        {renderBtnHistory(row)}
        {renderBtnDelete(row)}
      </Box>
    );
  };
  const renderBtnDelete = (row) => {
    return (
      <FabButton
        label="Delete"
        color="secondary"
        onClick={(e: React.MouseEvent) => onDelete(e, row._id)}
      >
        <DeleteIcon />
      </FabButton>
    );
  };
  const renderBtnAccountBalance = (row) => {
    return (
      <FabButton
        label="Add Account Balance"
        onClick={(e: React.MouseEvent) =>
          history.push(`/merchants/account-balance/${row._id}`)
        }
      >
        <CurrencyExchangeIcon sx={{ color: "rgb(102, 49, 247)" }} />
      </FabButton>
    );
  };
  const renderBtnEdit = (row) => {
    return (
      <FabButton
        label="Edit"
        onClick={(e: React.MouseEvent) =>
          history.push(`/merchants/form/${row._id}`)
        }
      >
        <EditIcon />
      </FabButton>
    );
  };

  return (
    <>
      <PageHeader
        title="Merchants"
        onAdd={() => history.push("/merchants/form/new")}
        addText="Create Merchant"
      />
      <Box
        sx={{
          marginY: "1rem",
          borderTop: "4px solid #6631F7",
          borderTopLeftRadius: "4px",
          borderTopRightRadius: "4px",
        }}
      >
        <Card>
          <CardContent sx={{ height: 700, width: "100%" }}>
            <DataGrid
              rows={data}
              columns={columns}
              pageSize={pageSize}
              rowsPerPageOptions={[25, 50, 100, 500]}
              getRowId={(row) => row._id}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              disableSelectionOnClick
              components={{
                Toolbar: GridToolbar,
              }}
            />
          </CardContent>
        </Card>
      </Box>
    </>
  );
};

/**
 * Maps state variables from redux store to props of currect component
 * @param state
 */
const mapStateToProps = (state: RootState) => ({
  state: state.merchants,
});

/**
 * Maps actions from slices to props
 */
const mapDispatchToProps = {
  getMerchants: merchantsActions.getMerchants,
  getMerchantRevenue: merchantsActions.getMerchantRevenue,
  deleteMerchant: merchantsActions.deleteMerchant,
  toggleMerchantStatus: merchantsActions.toggleMerchantStatus,
};

/**
 * Connects component to redux store
 */
const connector = connect(mapStateToProps, mapDispatchToProps);
const MerchantsComponentRedux = connector(MerchantsComponent);

export { MerchantsComponentRedux as MerchantsComponent };
