import React, { useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import moment from "moment";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Box from "@mui/material/Box";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import TimelineDot from "@mui/lab/TimelineDot";
import { useHistory } from "react-router-dom";
import PageHeader from "&styled/page-header";
import { RootState } from "&store/store";
import { carriersActions } from "../carriers.slice";

type ReduxProps = ConnectedProps<typeof connector>;

const CarriersHistoryComponent = (props: ReduxProps) => {
  const [data, setData] = useState<any>({});
  const history = useHistory();
  const { getCarrier, carrierId } = props;

  useEffect(() => {
    (async () => {
      const { payload } = await getCarrier(carrierId);
      setData(payload);
    })();
  }, [getCarrier, carrierId]);

  return (
    <>
      <PageHeader title={`${data.name} History`} />
      <Box
        sx={{
          marginY: "1rem",
        }}
      >
        <Grid container>
          <Grid item lg={12} sx={{ height: "80vh", overflow: "auto" }}>
            <Timeline position="right">
              {data.history?.length > 0 &&
                data.history.map((item, index) => (
                  <TimelineItem key={index}>
                    <TimelineOppositeContent
                      color="text.secondary"
                      style={{
                        marginLeft: "0px",
                        maxWidth: "8rem",
                        color: "#111111",
                        fontSize: "12px",
                      }}
                    >
                      {moment(item.date).format("ddd DD MMM YYYY")}
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                      <TimelineDot sx={{ bgcolor: "#6631F7" }} />
                      <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>
                      <Box sx={{ width: "100%" }}>
                        <Card elevation={0}>
                          <CardContent>
                            <Box
                              sx={{ display: "flex", flexDirection: "column" }}
                            >
                              <Box sx={{ color: "#111111", fontSize: "14px" }}>
                                {item.description}
                              </Box>
                              <Box sx={{ color: "#95989A", fontSize: "12px" }}>
                                {moment(item.date).format("HH:mm A")}
                              </Box>
                            </Box>
                          </CardContent>
                        </Card>
                      </Box>
                    </TimelineContent>
                  </TimelineItem>
                ))}
            </Timeline>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

/**
 * Maps state variables from redux store to props of currect component
 * @param state
 */
const mapStateToProps = (state: RootState, ownProps) => ({
  carrierId: ownProps.match.params.carrierId,
});

/**
 * Maps actions from slices to props
 */
const mapDispatchToProps = {
  getCarrier: carriersActions.getCarrier,
};

/**
 * Connects component to redux store
 */
const connector = connect(mapStateToProps, mapDispatchToProps);
const CarriersHistoryComponentRedux = connector(CarriersHistoryComponent);

export { CarriersHistoryComponentRedux as CarriersHistoryComponent };
