import * as React from "react";
import moment from "moment";
import Button from "@mui/material/Button";
import ReactExport from "react-export-excel";
import ExportIcon from "&assets/icons/export";
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
const ExcelFile = ReactExport.ExcelFile;

export default function ExportButton(props) {
  const renderContents = () => {
    if (props.name === "File Record") {
      return (
        <ExcelSheet data={props.data} name={props.name}>
          <ExcelColumn label="File Name" value="fileName" />
          <ExcelColumn label="Record Updated" value="updatedRecord" />
          <ExcelColumn label="No. Of Transactions" value="noOfTransactions" />
          <ExcelColumn
            label="Uploaded Date"
            value={(col) => moment(col.createdDate).format("DD/MM/YYYY")}
          />
          <ExcelColumn
            label="Date From"
            value={(col) => moment(col.dateFrom).format("DD/MM/YYYY")}
          />
          <ExcelColumn
            label="Date To"
            value={(col) => moment(col.dateTo).format("DD/MM/YYYY")}
          />
        </ExcelSheet>
      );
    } else {
      return (
        <ExcelSheet data={props.data} name={props.name}>
          <ExcelColumn label="Referance Number" value="referanceNumber" />
          <ExcelColumn label="Merchant" value="merchantName" />
          <ExcelColumn label="Order ID" value="merchantOrderId" />
          <ExcelColumn label="Amount" value="amount" />
          <ExcelColumn label="Name" value="fullName" />
          <ExcelColumn label="MSISDN" value="msisdn" />
          <ExcelColumn label="Status" value="status" />
          <ExcelColumn
            label="Created Date"
            value={(col) => moment(col.createdDate).format("DD/MM/YYYY")}
          />
          <ExcelColumn
            label="Created Time"
            value={(col) => moment(col.createdDate).format("HH:mm:ss")}
          />
          <ExcelColumn
            label="Date From"
            value={(col) => moment(col.dateFrom).format("DD/MM/YYYY")}
          />
          <ExcelColumn
            label="Date To"
            value={(col) => moment(col.dateTo).format("DD/MM/YYYY")}
          />
        </ExcelSheet>
      );
    }
  };
  return (
    <ExcelFile
      element={
        <Button
          sx={{
            border: "1px solid #6631F7",
            backgroundColor: "#6631F7",
            display: "flex",
            gap: 1,
            alignItems: "center",
            borderRadius: "5px",
            padding: "0.5rem 1rem",
            color: "#ffffff",
            "&:hover": {
              backgroundColor: "#6631F7",
              color: "#ffffff",
            },
          }}
        >
          <ExportIcon />
          Export
        </Button>
      }
      filename={`Loan_Disbursements_${moment().format("DD-MM-YYYY")}`}
    >
      {renderContents()}
    </ExcelFile>
  );
}
