import React, { useState, useEffect, useCallback } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Formik, FormikProps } from "formik";
import * as Yup from "yup";
import { useHistory } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Box from "@mui/material/Box";
import PageHeader from "&styled/page-header";
import { RootState } from "&store/store";
import { packagesActions } from "&features/packages/packages.slice";
import { SubmitButton } from "&styled/button/button.component";
import { TextInput } from "&styled/textField/textField.component";
import { getCurrentUser } from "&config/getCurrentUser";
import { servicesActions } from "&features/services/services.slice";
import SelectComponent from "&styled/form/select";
import  countries from './mockData/countries.json';
import currencies from './mockData/currencies.json';

type ReduxProps = ConnectedProps<typeof connector>;

const PackageFormComponent = (props: ReduxProps) => {
  const history = useHistory();
  const { getPackage, addPackage, editPackage, token, packageId,getServices } = props;
  const user: any = getCurrentUser(token);
  const [services, setServices] = useState([])

  const [data, setData] = useState({
    _id: "",
    name: "",
    amount: "",
    callbackUrl: "",
    currency: "",
    country:"",
    serviceId: ""
  });

  const validation = Yup.object().shape({
    name: Yup.string()
      .min(2, "Too short account name.")
      .max(50, "Too long account name")
      .required("Please provide valid package name"),
    amount: Yup.string()
      .matches(/^[0-9]+$/, 'Amount must contain only digits from 0 to 9')
      .required("Please provide valid amount"),
    callbackUrl: Yup.string().optional().url(),
    country: Yup.string().trim()
      .required("Please provide valid country"),
    currency: Yup.string().trim()
      .required("Please provide valid currency"),
    serviceId: Yup.string().trim()
      .required("Please provide valid service")
  });

  const fetchPackage = useCallback(
    async (id) => {
      try {
        const { payload } = await getPackage(id);

        setData({
          _id: payload._id,
          name: payload.name,
          amount: payload.amount,
          callbackUrl: payload.callbackUrl,
          serviceId: payload.serviceId,
          country: payload.country,
          currency: payload.currency,
         
        });
      } catch (ex) {
        console.log(ex);
        alert("Something went wrong");
      }
    },
    [getPackage]
  );

  useEffect(() => {
    const id = packageId;
    (async()=>{
      const {payload} = await getServices()
      if(payload.length){
        const servicesArr: any = [];
        payload.forEach((s)=>{
          servicesArr.push({label: s.serviceName, value: s.serviceId})
        })
        setServices(servicesArr)
      }
    })()
    if (id === "new") return;
    fetchPackage(id);
  }, [fetchPackage, getServices, packageId]);

  const handleSubmit = async (vals) => {
    if (!vals.userTypeId) {
      vals["userTypeId"] = user.userTypeId;
    }
    if (data._id) {
      await editPackage(vals);

      history.push("/packages");
    } else {
      await addPackage(vals);

      history.push("/packages");
    }
  };

  return (
    <>
      <PageHeader title="Package Form" />
      <Box
        sx={{
          marginY: "1rem",
          borderTop: "4px solid #6631F7",
          borderTopLeftRadius: "4px",
          borderTopRightRadius: "4px",
          height: "80vh",
          overflow: "auto",
        }}
      >
        <Card>
          <CardContent>
            <Formik
              enableReinitialize={true}
              initialValues={data}
              validateOnChange={true}
              validateOnBlur={true}
              onSubmit={(values: typeof data) => {
                handleSubmit(values);
              }}
              validationSchema={validation}
            >
              {(formik: FormikProps<typeof data>) => (
                <Grid container spacing={2}>
                  <Grid item lg={4} xs={12}>
                    <TextInput
                      placeHolder={"Package Name"}
                      value={formik.values.name}
                      handleTextChange={formik.handleChange("name")}
                      hasError={!!formik.errors.name}
                      errorMessage={formik.errors.name as string}
                    />
                  </Grid>
                  <Grid item lg={8} sm={12}></Grid>

                  <Grid item lg={4} sm={12}>
                  <SelectComponent
                    onSelect={formik.handleChange("country")}
                    value={formik.values.country}
                    size="small"
                    placeHolder="Select Country"
                    menuItems={countries || []}
                    hasError={!!formik.errors.country}
                    errorMessage={formik.errors.country as string}
                  />
                  </Grid>
                  <Grid item lg={8} sm={12}></Grid>

                  <Grid item lg={4} sm={12}>
                  <SelectComponent
                    onSelect={formik.handleChange("currency")}
                    value={formik.values.currency}
                    size="small"
                    placeHolder="Select Currency"
                    menuItems={currencies.filter((c:any)=> c.country === formik.values.country) || []}
                    hasError={!!formik.errors.country}
                    errorMessage={formik.errors.country as string}
                  />
                  </Grid>
                  <Grid item lg={8} sm={12}></Grid>

                  <Grid item lg={4} sm={12}>
                    <TextInput
                      placeHolder={"Amount"}
                      value={formik.values.amount}
                      handleTextChange={formik.handleChange("amount")}
                      hasError={!!formik.errors.amount}
                      errorMessage={formik.errors.amount as string}
                    />
                  </Grid>

                  <Grid item lg={8} sm={12}></Grid>
                  <Grid item lg={4} sm={12}>
                    <TextInput
                      placeHolder={"Callback URL"}
                      value={formik.values.callbackUrl}
                      handleTextChange={formik.handleChange("callbackUrl")}
                      hasError={!!formik.errors.callbackUrl}
                      errorMessage={formik.errors.callbackUrl as string}
                    />
                  </Grid>

                  <Grid item lg={8} sm={12}></Grid>
                  <Grid item lg={4} sm={12}>
                  <SelectComponent
                    onSelect={formik.handleChange("serviceId")}
                    value={formik.values.serviceId}
                    size="small"
                    placeHolder="Select Service"
                    menuItems={services || []}
                    hasError={!!formik.errors.serviceId}
                    errorMessage={formik.errors.serviceId as string}
                  />
                  </Grid>

                  <Grid item lg={8} sm={12}></Grid>
                  <Grid item lg={4} sm={12}>
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      marginY: "1.5rem",
                    }}
                  >
                    <Box sx={{ width: "30%" }}>
                      <SubmitButton
                        title="Save"
                        handlePress={() => {
                          formik.handleSubmit();
                        }}
                      />
                    </Box>
                  </Box>
                  </Grid>
                </Grid>
              )}
            </Formik>
          </CardContent>
        </Card>
      </Box>
    </>
  );
};

/**
 * Maps state variables from redux store to props of currect component
 * @param state
 */
const mapStateToProps = (state: RootState, ownProps) => ({
  packageId: ownProps.match.params.packageId,
  token: state.login.token,
});

/**
 * Maps actions from slices to props
 */
const mapDispatchToProps = {
  getPackage: packagesActions.getPackage,
  addPackage: packagesActions.addPackage,
  editPackage: packagesActions.editPackage,
  getServices: servicesActions.getServices
};

/**
 * Connects component to redux store
 */
const connector = connect(mapStateToProps, mapDispatchToProps);
const PackageFormComponentRedux = connector(PackageFormComponent);

export { PackageFormComponentRedux as PackageFormComponent };
