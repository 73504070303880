
import {createHmac} from 'crypto';

const hmacSecret = process.env.REACT_APP_HMAC_SECRET!;

export function hmacCalculation(obj) {
    const {amount_cents,created_at,currency,error_occured,has_parent_transaction,id,integration_id,is_3d_secure,is_auth,is_capture,is_refunded,is_standalone_payment,is_voided,order,owner, pending, source_pan,source_data_subtype,source_data_type,success} = obj;
  
    try {
  const hmacString = amount_cents+created_at+currency+error_occured+has_parent_transaction+id+integration_id+is_3d_secure+is_auth+is_capture+is_refunded+is_standalone_payment+is_voided+order+owner+pending+source_pan+source_data_subtype+source_data_type+success;
 console.log("hmac string is :", hmacString)
 console.log("hmac secret is :", hmacSecret)
  const hash = createHmac('sha512', hmacSecret).update(hmacString).digest('hex');
    if(hash !== obj.hmac) return false;
    return true; 
    } catch (ex) {
        return null;
    }
    }
