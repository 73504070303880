import  React,{useEffect, useState} from "react";
import Box from "@mui/material/Box";
import CloseIcon from "@mui/icons-material/Close";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DoneIcon from '@mui/icons-material/Done';
import Modal from "@mui/material/Modal";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 720,
  bgcolor: "background.paper",
  boxShadow: 24,
  border: "1px solid #707070",
  borderRadius: "8px",
  p: 1,
};

export default function LinkModal({
  open,
  handleClose,
 link
}) {
const [isCopied, setIsCopied] = useState(false)

useEffect(()=>{},[isCopied])

  const handleCopy = (text) => {
    navigator.clipboard.writeText(text)
      .then(() => {
        setIsCopied(true)
        const timer = setTimeout(() => {
          setIsCopied(false)
        },1000)
        return ()=>{ clearTimeout(timer)}
      })
      .catch((error) => {
        console.error('Copy to clipboard failed:', error);
      });
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="link-modal"
        aria-describedby="link-modal"
      >
        <Box sx={style}>
          {/*Header*/}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box sx={{ font: "normal normal 600 20px/30px Poppins" }}>
              Payout Link
            </Box>
            <CloseIcon style={{ cursor: "pointer" }} onClick={handleClose} />
          </Box>
          <Box
            sx={{
              padding: "1rem",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              width: "100%",
            }}
          >
            <Box sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              border: "1px solid #eee",
              width: "100%",
              padding: "1rem 0.5rem"
            }}>
              {link}
          {!isCopied && <ContentCopyIcon onClick={()=>handleCopy(link)} sx={{ cursor: 'pointer' }}/>}
          {isCopied && <DoneIcon />}
              </Box>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
